import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import axios from "axios";

import logo from "./images/logo.png";
import Logout from "./images/Logout.svg";
import Setting from "./images/Setting.svg";
import Menu from "./images/Menu.svg";
import { useEffect } from "react";
import { MainContext } from "../../../../App";

const TAB_LIST_ARRAY = [
  {
    id: 0,
    title: "Linux",
    href: "/linux",
  },
  {
    id: 1,
    title: "Python",
    href: "/python",
  },
  {
    id: 2,
    title: "Data Science",
    href: "/data_science",
  },
  {
    id: 3,
    title: "Visualization",
    href: "/visualization",
  },
  {
    id: 4,
    title: "Finance Applications",
    href: "/finance_applications",
  },
  {
    id: 5,
    title: "Log out",
    href: "/",
  },
  {
    id: 6,
    title: "Settings",
    href: "/settings",
  },
];

const TopBar = ({ open, setOpen }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const context = React.useContext(MainContext);

  useEffect(() => {
    const topicType = pathname.split("/")[1];
    if (topicType === "linux") {
      setSelectedTab(0);
      return;
    }
    if (topicType === "python") {
      setSelectedTab(1);
      return;
    }
    if (topicType === "data_science") {
      setSelectedTab(2);
      return;
    }
    if (topicType === "visualization") {
      setSelectedTab(3);
      return;
    }
    if (topicType === "finance_applications") {
      setSelectedTab(4);
      return;
    }
    if (topicType === "settings") {
      setSelectedTab(6);
      return;
    }
  }, [pathname]);
  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    try {
      setLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token} `,
      };
      await axios.post(
        `${process.env.REACT_APP_QA_API}/logout`,
        {},
        { headers }
      );
      setLoading(false);
      localStorage.clear();
      context.login.setIsLoggedIn(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      {/* Mobile device */}
      <div
        className={`text-white ${
          open && "h-screen flex justify-between flex-col bg-gray-g1 "
        }`}
      >
        <div>
          {loading && <div className="h-screen text-white">Loading...</div>}
          <div className="md:hidden bg-gray-g1 flex px-4 py-4 md:mx-0 justify-between md:justify-center items-center text-black bg-[#ffa724]">
            <Link to="/" className="flex items-center justify-between">
              <img src={logo} alt="logo" className="w-8 m-2 " />
              <h1 className="vantage-h1 text-2xl font-extrabold leading-48 tracking-tight">QuantFinancePrep</h1>
            </Link>
            <img
              src={Menu}
              alt="menu"
              className={` ${
                open && " p-2 bg-gray-g8 rounded-lg cursor-pointer"
              }`}
              onClick={() => setOpen(!open)}
            />
          </div>

          <div
            className={`${
              open ? "md:hidden mx-4 my-8 flex flex-col text-lg" : "hidden"
            } `}
          >
            <TabList
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              open={open}
              setOpen={setOpen}
            />
          </div>
        </div>
        <div
          className={`${
            open ? "md:hidden mx-4 flex flex-col text-lg" : "hidden"
          } `}
        >
          <div
            className="px-2 py-3 w-full flex gap-6 rounded-full hover:bg-gray-g8 cursor-pointer"
            onClick={handleLogout}
          >
            <img src={Logout} alt="Logout" className="w-5" /> Log out
          </div>
          <Link
            to="/settings"
            className={`px-2 py-3 w-full flex gap-6 rounded-full hover:bg-gray-g8 cursor-pointer ${
              selectedTab === 6 && "bg-gray-g8"
            }`}
            onClick={() => setOpen(!open)}
          >
            <img src={Setting} alt="Setting" className="w-5" />
            Settings
          </Link>
        </div>
        <div />
      </div>
      {/* web view */}
      {/* <div className="hidden md:flex sticky h-screen bg-gray-g1 text-white lg:w-7">
        <button className="border-solid bg-gray-g8 rounded float-right px-2 text-2xl" onClick={() => setIsCollapsed(!isCollapsed)}>{isCollapsed ? "»" : "«"}</button>
      </div> */}
      <div
        className={`hidden md:block sticky w-full ${
          !isCollapsed ? "lg:w-full" : "lg:w-0"
        } text-black bg-[#ffa724]`}
      >
        <div className="w-full flex flex-col justify-between ">
          {/* 1 */}
          <div className="w-full flex justify-between h-12">
            <div
              className={`mx-4 flex flex-row text-lg ${
                isCollapsed && "hidden"
              }`}
            >
              <TabList
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                open={null}
                setOpen={null}
              />
            </div>
            <Link to="/" className="flex items-center justify-between mr-4">
              <img
                src={logo}
                alt="Logo"
                className={`hidden ${
                  !isCollapsed && "md:block"
                } w-8 m-2 cursor-pointer`}
              />
              <h1 className="vantage-h1 text-2xl font-extrabold leading-48 tracking-tight">QuantFinancePrep</h1>
            </Link>
          </div>
          {/* 2 */}
          
        </div>
      </div>
    </>
  );
};

export default TopBar;

function TabList({ setSelectedTab, selectedTab, open, setOpen }) {
  const context = React.useContext(MainContext);

  const onChangeTab = (id) => {
    setSelectedTab(id);
    context.setActiveUri("");
    try {
      setOpen(!open);
    } catch {}
  };
  return (
    <>
      <div className="md:hidden flex flex-row justify-between">
        <div>
          {TAB_LIST_ARRAY.slice(0, 5).map(({ id, title, href }) => (
            <Link to={href} key={title}>
              <div
                className={`px-5 py-3 w-full flex flex-row gap-6 rounded-full cursor-pointer ${
                  selectedTab === id ? "text-white" : "text-gray-g11"
                }`}
                key={id}
                onClick={() => onChangeTab(id)}
              >
                {title}
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="hidden md:block">
        <div className="flex flex-row justify-between">
          {TAB_LIST_ARRAY.slice(0, 5).map(({ id, title, href }) => (
            <Link to={href} key={title}>
              <div
                className={`px-5 py-3 w-full flex flex-row gap-6 rounded-full cursor-pointer ${
                  selectedTab === id ? "text-black" : "text-gray-g8"
                }`}
                key={id}
                onClick={() => onChangeTab(id)}
              >
                {title}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
