import React from "react";
import ReactDOM from "react-dom";
import Slider from "react-slick";
import "./index.css";


export default function SimpleSlider({images, successRate}){
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 7,
      speed: 500,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2
          }
        },
      ]
    };
    var test123 = 'test123';
    return (
      <div className="px-3 bg-green-100 pb-12 pt-4">
        <p className="text-green-g2 text-center mt-4 mb-8 font-bold">
          {"As of Apr 30, 2024, over "}
          <span className="text-green-g1 text-2xl font-bold">
            {successRate}%
          </span>
          {" candidates from leading universities and financial firms who took a 3-6 month subscription of QuantFinancePrep have landed placements in top-tier companies."}
        </p>
        <Slider {...settings}>
          {images.map((img, index) => {
            return (
              <div key={index} className="px-1">
                <div className="p-1 shadow-md cursor-pointer flex items-center justify-center bg-green-200 rounded-lg h-20 w-auto mx-auto">
                  <img src={img.url} className=" max-w-full max-h-full object-contain" />
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    );
  }
